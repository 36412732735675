<template>
  <base-modal>
    <h1>{{ tr(title) }}</h1>
    <table class="standalone-checkbox">
      <tr class="mdc-data-table__row">
        <td class="mdc-data-table__cell mdc-data-table__cell--checkbox">
          <check-box extra-class="user-checkbox" id="all-users-with-link" :is-checked="allUsersWithLinkChecked"
            @checked="onAllUsersWithLinkChecked">
          </check-box>
        </td>
        <td class="mdc-data-table__cell user-name" :style="collaboratorStyle">
          <label for="all-users-with-link" :style="fontStyle">
            {{ tr('All users with the link') }}
          </label>
        </td>
      </tr>
    </table>
    <table class="outer-table">
      <tr class="outer-table">
        <td class="left-outer-column">
          <table class="inner-table">
            <tr v-for="user in usersCol1" :key="user.id" class="mdc-data-table__row">
              <td class="mdc-data-table__cell mdc-data-table__cell--checkbox">
                <check-box extra-class="user-checkbox" :id="user.id" :is-checked="!!user.checked"
                  @checked="onUserChecked(user.id, $event)">
                </check-box>
              </td>
              <td class="mdc-data-table__cell user-name" :style="collaboratorStyle">
                <label :for="user.id" :style="fontStyle">
                  {{ user.displayName || shortenId(user.id) }}
                </label>
              </td>
            </tr>
          </table>
        </td>
        <td>
          <table class="inner-table">
            <tr v-for="user in usersCol2" :key="user.id" class="mdc-data-table__row">
              <td class="mdc-data-table__cell mdc-data-table__cell--checkbox">
                <check-box extra-class="user-checkbox" :id="user.id" :is-checked="!!user.checked"
                  @checked="onUserChecked(user.id, $event)">
                </check-box>
              </td>
              <td class="mdc-data-table__cell user-name" :style="collaboratorStyle">
                <label :for="user.id" :style="fontStyle">
                  {{ user.displayName || shortenId(user.id) }}
                </label>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>

    <div class="modal-footer" :style="footerStyle">
      <button class="modal-button cancel-button" @click.prevent="onCancel" :style="buttonStyle">{{ tr('Cancel')
      }}</button>
      <button class="modal-button add-button" @click.prevent="onAddUsers" :style="buttonPrimaryStyle">{{ tr('Update')
      }}</button>
    </div>
  </base-modal>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import BaseModal from '@/components/ui/BaseModal.vue'
import CheckBox from '@/components/ui/CheckBox/CheckBox.vue'
import { isThemeLight, themeColors } from '@/common/shared.js'

export default {
  components: {
    BaseModal,
    CheckBox,
  },
  props: {
    currentUserIds: {
      type: Array,
      required: true,
    },
    onUpdateUserIds: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore()
    const tr = inject('tr')

    const checkedUserIds = computed(() => {
      const result = {}
      props.currentUserIds.forEach((id) => result[id] = true)
      return result
    })

    const allUsersWithLinkChecked = computed(() => {
      return !!checkedUserIds.value['all-users-with-link']
    })

    const onAllUsersWithLinkChecked = (newValue) => {
      if (checkedUserIds.value['all-users-with-link'] === newValue) { return }
      checkedUserIds.value['all-users-with-link'] = newValue
    }

    const users = computed(() => {
      const subset = store.getters.allUsers.filter((u) => u.id !== store.getters.userId)
      subset.forEach((u, index) => {
        subset[index].checked = checkedUserIds.value[u.id]
      })
      subset.sort((a, b) => a.displayName.localeCompare(b.displayName))
      return subset
    })

    const onAddUsers = () => {
      const checkboxes = document.querySelectorAll('.user-checkbox:checked')
      const userIds = Array.from(checkboxes).map((el) => el.id)
      props.onUpdateUserIds(userIds)
      context.emit('close')
    }

    const onCancel = () => {
      context.emit('close')
    }

    const usersCol1 = computed(() => {
      return users.value.filter((_, index) => index % 2 === 0)
    })
    const usersCol2 = computed(() => {
      return users.value.filter((_, index) => index % 2 !== 0)
    })

    const buttonStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].toolInputBackgroundColor,
        borderColor: themeColors[store.getters.currentThemeName].modalTextColor,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
      }
    })

    const buttonPrimaryStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].buttonBorderColor,
        color: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        border: `${themeColors[store.getters.currentThemeName].buttonBorderColor}`,
      }
    })

    const footerStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
      }
    })

    const collaboratorStyle = computed(() => {
      return {
        color: themeColors[store.getters.currentThemeName].modalTextColor,
      }
    })

    const checkboxColor = computed(() => store.getters.checkboxColor)

    const checkboxOutline = computed(() => {
      return {
        outlineColor: isThemeLight[store.getters.currentThemeName] ? '#000' : '#fff',
      }
    })

    const fontStyle = computed(() => {
      return {
        fontFamily: store.getters.font,
      }
    })

    const onUserChecked = (id, newValue) => {
      if (checkedUserIds.value[id] === newValue) { return }
      checkedUserIds.value[id] = newValue
    }

    return {
      allUsersWithLinkChecked,
      buttonStyle,
      buttonPrimaryStyle,
      checkboxColor,
      checkboxOutline,
      collaboratorStyle,
      fontStyle,
      footerStyle,
      onAddUsers,
      onAllUsersWithLinkChecked,
      onCancel,
      onUserChecked,
      tr,
      usersCol1,
      usersCol2,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/pages/Dashboard/tables.scss';

h1 {
  font-size: 2rem;
  padding-top: 1rem;
}

table.standalone-checkbox {
  max-width: 100%;
  border-collapse: collapse;
  margin: 2rem auto;
}

table.outer-table {
  max-width: 100%;
  margin: 2rem auto 0 auto;
}

table.inner-table {
  border-collapse: collapse;
}

tr.outer-table {
  display: inline-flex;
}

tr.mdc-data-table__row {
  border: none;
}

.left-outer-column {
  padding-right: 3rem;
}

.user-name {
  text-align: left;
  font-size: 1em;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}

label {
  cursor: pointer;
  display: block;
  padding-top: 1rem;
  padding-right: 1rem;
  height: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modal-button {
  font: inherit;
  margin: 1rem 3rem;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1em;
  border-radius: 40px;


  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }
}

.modal-footer {
  margin: 0;
  position: sticky;
  bottom: 0;

}

.cancel-button {
  position: sticky;
  right: 100%;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 40px;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }
}

.add-button {
  position: sticky;
  left: 100%;
}

:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

:focus:not(:focus-visible) {
  transform: scale(1);
}
</style>
