<template>
  <base-modal>
    <h1>{{ tr('Create new session') }}</h1>
    <h2>{{ tr('Choose a starting template:') }}</h2>
    <table>
      <tr>
        <td>
          <ul>
            <li v-for="template in templatesCol1" :key="template.id" @click.prevent="onClick(template.id)" role="button"
              tabindex="0" @keyup.enter="onClick(template.id)" :style="templateChoiceStyle">
              {{ template.name[language] }}
            </li>
          </ul>
        </td>
        <td>
          <ul>
            <li v-for="template in templatesCol2" :key="template.id" @click.prevent="onClick(template.id)" role="button"
              tabindex="0" @keyup.enter="onClick(template.id)" :style="templateChoiceStyle">
              {{ template.name[language] }}
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <div class="modal-footer" :style="footerStyle">
      <button class="modal-footer-button" :style="buttonStyle" @click.prevent="onCancel">{{ tr('Cancel') }}</button>
    </div>
  </base-modal>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import BaseModal from '@/components/ui/BaseModal.vue'
import { themeColors } from '@/common/shared.js'

export default {
  components: {
    BaseModal,
  },
  setup(_, context) {
    const store = useStore()
    const tr = inject('tr')

    const templates = computed(() => store.getters.sessionTemplates)
    const language = computed(() => store.getters.profile.language)
    const tutorialNext = inject('tutorialNext')

    const onClick = (id) => {
      const template = templates.value.filter((t) => t.id === id).shift()
      const name = template.name['en_US.UTF-8'].replace(/[()]/g, '')
      const tools = template.tools
      const session = { name, tools }
      store.dispatch('duplicateSessionTemplate', session)
      context.emit('close')
      setTimeout(tutorialNext, 300)  // Required to allow switch to session.
    }

    const onCancel = () => {
      context.emit('close')
    }

    const buttonStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
        border: `${themeColors[store.getters.currentThemeName].buttonBorderColor}`,
      }
    })

    const footerStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
      }
    })

    const templateChoiceStyle = computed(() => {
      return {
        border: `1px solid ${themeColors[store.getters.currentThemeName].buttonBorderColor}`,
      }
    })

    const templatesCol1 = computed(() => {
      return templates.value.filter((_, index) => index % 2 === 0)
    })
    const templatesCol2 = computed(() => {
      return templates.value.filter((_, index) => index % 2 !== 0)
    })

    return {
      buttonStyle,
      footerStyle,
      language,
      onCancel,
      onClick,
      templateChoiceStyle,
      templatesCol1,
      templatesCol2,
      tr,
    }
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 2rem;
  padding-top: 1rem;
}

h2 {
  font-size: 1.5rem;
}

table {
  width: 100%;
  padding-bottom: 1rem;
}

tr {
  display: inline-flex;
}

ul {
  padding-left: 0;
}

li {
  display: flex;
  margin: 1rem 3rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: 12px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);

  &:hover,
  &:focus {
    transform: scale(1.2);
  }

  &:focus:not(:focus-visible) {
    transform: scale(1);

    &:hover {
      transform: scale(1.2);
    }
  }
}

li:last-of-type {
  margin-bottom: 4.475rem;
}

.modal-footer {
  width: 100%;
  margin: 0;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

}

.modal-footer-button {
  font: inherit;
  margin: 1rem 3rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1em;
  border-radius: 12px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;


  &:hover,
  &:focus {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }

  &:focus:not(:focus-visible) {
    transform: scale(1);

    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
