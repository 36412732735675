<template>
  <thead>
    <tr class="mdc-data-table__header-row">
      <th
        class="mdc-data-table__header-cell"
        role="columnheader"
        scope="col"
        :style="[tableStyle, fontStyle]"
      >{{ tr('Owner') }}</th>
      <th
        class="mdc-data-table__header-cell"
        role="columnheader"
        scope="col"
        :style="[tableStyle, fontStyle]"
      >{{ tr('Session ID') }}</th>
      <th
        class="mdc-data-table__header-cell"
        role="columnheader"
        scope="col"
        :style="[tableStyle, fontStyle]"
      >{{ tr('Session Name') }}<span
          class="dashboard-tip"
          tabindex="0"
          @focus="showTipParagraph('shared-table-session-name-tip-paragraph')"
          @blur="hideTipParagraph('shared-table-session-name-tip-paragraph')"
        >
          <info-circle></info-circle>
          <p
            :style="tipParagraphStyle"
            id="shared-table-session-name-tip-paragraph"
          >{{ tr('shared-dashboard-tip-session-name') }}</p>
        </span></th>
      <th
        class="mdc-data-table__header-cell"
        role="columnheader"
        scope="col"
        :style="[tableStyle, fontStyle]"
      >{{ tr('Session Link') }}<span
          class="dashboard-tip"
          tabindex="0"
          @focus="showTipParagraph('shared-table-session-link-tip-paragraph')"
          @blur="hideTipParagraph('shared-table-session-link-tip-paragraph')"
        >
          <info-circle></info-circle>
          <p
            :style="tipParagraphStyle"
            id="shared-table-session-link-tip-paragraph"
          >{{ tr('shared-dashboard-tip-session-link') }}</p>
        </span></th>
      <th
        class="mdc-data-table__header-cell"
        role="columnheader"
        scope="col"
        :style="[tableStyle, fontStyle]"
      >{{ tr('Number of Tools') }}</th>
      <th
        class="mdc-data-table__header-cell"
        role="columnheader"
        scope="col"
        :style="[tableStyle, fontStyle]"
      >{{ tr('Locally Anonymized') }}<span
          class="dashboard-tip dashboard-tip-left"
          tabindex="0"
          @focus="showTipParagraph('shared-table-locally-anonymized-tip-paragraph')"
          @blur="hideTipParagraph('shared-table-locally-anonymized-tip-paragraph')"
        >
          <info-circle></info-circle>
          <p
            :style="tipParagraphStyle"
            id="shared-table-locally-anonymized-tip-paragraph"
          >{{ tr('shared-dashboard-tip-locally-anonymized') }}</p>
        </span></th>
      <th
        class="mdc-data-table__header-cell"
        role="columnheader"
        scope="col"
        :style="[tableStyle, fontStyle]"
      >{{ tr('Last accessed') }}</th>
      <th
        class="mdc-data-table__header-cell"
        role="columnheader"
        scope="col"
        :style="[tableStyle, fontStyle]"
      >{{ tr('Duplicate') }}<span
          class="dashboard-tip dashboard-tip-left"
          tabindex="0"
          @focus="showTipParagraph('shared-table-duplicate-session-tip-paragraph')"
          @blur="hideTipParagraph('shared-table-duplicate-session-tip-paragraph')"
        >
          <info-circle></info-circle>
          <p
            :style="tipParagraphStyle"
            id="shared-table-duplicate-session-tip-paragraph"
          >{{ tr('shared-dashboard-tip-duplicate-session') }}</p>
        </span></th>
      <th
        class="mdc-data-table__header-cell"
        role="columnheader"
        scope="col"
        :style="[tableStyle, fontStyle]"
      >{{ tr('Sharing Status') }}<span
          class="dashboard-tip dashboard-tip-left"
          tabindex="0"
          @focus="showTipParagraph('shared-table-sharing-status-tip-paragraph')"
          @blur="hideTipParagraph('shared-table-sharing-status-tip-paragraph')"
        >
          <info-circle></info-circle>
          <p
            :style="tipParagraphStyle"
            id="shared-table-sharing-status-tip-paragraph"
          >
            {{ tr('shared-dashboard-tip-sharing-status') }}
          </p>
        </span></th>
    </tr>
  </thead>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { tipParagraphStyleFunc } from '@/common/shared.js'
import InfoCircle from '@/components/ui/icons/InfoCircle.vue'

import { tableStyle } from './tables.js'

export default {
  components: {
    InfoCircle,
  },
  setup() {
    const store = useStore()
    const tr = inject('tr')

    const tipParagraphStyle = computed(() => tipParagraphStyleFunc(store))

    const showTipParagraph = (elId) => document.getElementById(elId).style.display = 'block'
    const hideTipParagraph = (elId) => document.getElementById(elId).style.display = null

    const fontStyle = computed(() => {
      return {
        fontFamily: store.getters.font,
      }
    })

    return {
      fontStyle,
      hideTipParagraph,
      showTipParagraph,
      tableStyle,
      tipParagraphStyle,
      tr,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './tables.scss';
</style>
