<template>
  <tr
    data-row-id="u0"
    class="mdc-data-table__row"
  >
    <td
      class="mdc-data-table__cell"
      :style="[tableStyle, fontStyle]"
    >{{ session.ownerName }}</td>
    <td
      class="mdc-data-table__cell font-monospace"
      :style="tableStyle"
      :title="session.id"
    >{{ shortenId(session.id) }}</td>
    <td
      class="mdc-data-table__cell"
      :style="tableStyle"
    >
      <div
        class="form-control"
        :class="{invalid: sessionNameValidity === 'invalid' || !isUniqueSessionName}"
      >
        <input
          type="text"
          v-model.trim="sessionName"
          class="font-monospace table-input"
          :style="tableInputStyle"
        >
        <p
          class="error"
          v-if="sessionNameValidity === 'invalid'"
          :style="[errorStyle, fontStyle]"
        >{{ tr('Name cannot contain any of the following characters:') }}<br>! @ # $ % ^ &amp; * [ ] { } ; : ' " &lt; &gt; \ / |</p>
        <p
          class="error"
          v-if="!isUniqueSessionName"
          :style="[errorStyle, fontStyle]"
        >{{ tr('Your Session Name must be unique.') }}</p>
      </div>
    </td>
    <td
      class="mdc-data-table__cell"
      :style="tableStyle"
    >
      <router-link :to="sessionUrl(session.ownerName, session.name, session.id, isUniqueSessionName)">
        <go-to-session-icon class="session-icon"></go-to-session-icon>
      </router-link>
    </td>
    <td
      class="mdc-data-table__cell"
      :style="[tableStyle, fontStyle]"
    >{{ session.toolCount }}</td>
    <td
      class="mdc-data-table__cell mdc-data-table__cell--checkbox"
      :style="tableStyle"
    >
      <check-box
        :is-checked="locallyAnonymized"
        @checked="onLocallyAnonymized"
      >
      </check-box>
    </td>
    <td
      class="mdc-data-table__cell"
      :style="[tableStyle, fontStyle]"
      :title="lastAccessedDate(session.lastAccessed)"
    >{{ formatLastAccessed(session.lastAccessed) }}
    </td>
    <td
      class="mdc-data-table__cell"
      :style="tableStyle"
    >
      <duplicate-session-icon
        class="session-icon"
        @click.prevent="onDuplicateSession(session)"
        role="button"
        tabindex="0"
        @keyup.enter="onDuplicateSession(session)"
      ></duplicate-session-icon>
    </td>
    <td
      class="mdc-data-table__cell"
      :style="[tableStyle, fontStyle]"
    >
      <edit-viewers-icon
        class="shared-session-icon"
        v-if="sharingStatus === 'viewer'"
        :title="tr('Viewer')"
      ></edit-viewers-icon>
      <edit-collaborators-icon
        class="shared-session-icon"
        v-if="sharingStatus === 'collaborator'"
        :title="tr('Collaborator')"
      ></edit-collaborators-icon>
    </td>
  </tr>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'

import { calculateLastAccessDate, isThemeLight, nameIsValidRE, sanitizeUrlSegment, shortenId, themeColors, useDebouncedRef } from '@/common/shared.js'
import CheckBox from '@/components/ui/CheckBox/CheckBox.vue'
import DuplicateSessionIcon from '@/components/ui/icons/DuplicateSessionIcon.vue'
import EditCollaboratorsIcon from '@/components/ui/icons/EditCollaboratorsIcon.vue'
import EditViewersIcon from '@/components/ui/icons/EditViewersIcon.vue'
import GoToSessionIcon from '@/components/ui/icons/GoToSessionIcon.vue'

import { sessionUrl, tableInputStyle, tableStyle } from './tables.js'

export default {
  components: {
    CheckBox,
    DuplicateSessionIcon,
    EditCollaboratorsIcon,
    EditViewersIcon,
    GoToSessionIcon,
  },
  props: {
    formatLastAccessed: {
      type: Function,
      required: true,
    },
    onDuplicateSession: {
      type: Function,
      required: true,
    },
    sessionId: {
      type: String,
      required: true,
    },
    sharingStatus: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()
    const tr = inject('tr')

    const locallyAnonymized = ref(store.getters.profile.sessions[props.sessionId].locallyAnonymized)

    const session = reactive(store.getters.profile.sessions[props.sessionId])
    watch(store.getters.profile.sessions[props.sessionId], (newValue) => {
      session.value = newValue
      if (session.value.locallyAnonymized !== locallyAnonymized.value) {
        locallyAnonymized.value = session.value.locallyAnonymized
      }
    })

    const checkboxColor = computed(() => store.getters.checkboxColor)

    const checkboxOutline = computed(() => {
      return {
        outlineColor: isThemeLight[store.getters.currentThemeName] ? '#000' : '#fff',
      }
    })

    const errorStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].errorBackgroundColor,
        color: themeColors[store.getters.currentThemeName].errorTextColor,
      }
    })

    const sessionNameValidity = ref('pending')

    const sessionName = useDebouncedRef(session.name, 500)
    watch(sessionName, (newValue) => {
      if (newValue && !nameIsValidRE.test(newValue)) {
        sessionNameValidity.value = 'invalid'
        return
      }
      sessionNameValidity.value = 'valid'
      if (session.name === newValue) { return }
      const msg = {
        ownerId: session.ownerId,
        sessionId: session.id,
        sessionName: newValue,
        userId: store.getters.userId,
        publish: true,
      }
      store.dispatch('setSessionName', msg)
    })

    const onLocallyAnonymized = (newValue) => {
      if (session.locallyAnonymized === newValue) { return }
      const msg = {
        ownerId: session.ownerId,
        sessionId: session.id,
        locallyAnonymized: newValue,
        publish: true,
      }
      store.dispatch('setSessionLocallyAnonymized', msg)
    }

    const isUniqueSessionName = computed(() => {
      if (!session.name) { return true }
      const newName = sanitizeUrlSegment(session.name)
      return store.getters.sessionsSharedWithMe.reduce((acc, s) => {
        if (!s.name || s.id === session.id) { return acc }
        const differ = sanitizeUrlSegment(s.name) !== newName
        return acc && differ
      }, true)
    })

    const fontStyle = computed(() => {
      return {
        fontFamily: store.getters.font,
      }
    })

    const lastAccessedDate = (timeOfLastAccess) => calculateLastAccessDate(timeOfLastAccess, store)

    return {
      checkboxColor,
      checkboxOutline,
      errorStyle,
      fontStyle,
      isUniqueSessionName,
      lastAccessedDate,
      locallyAnonymized,
      onLocallyAnonymized,
      session,
      sessionName,
      sessionNameValidity,
      sessionUrl,
      shortenId,
      tableInputStyle,
      tableStyle,
      tr,
    }
  },
}
</script>

<style lang="scss" scoped>
@import './tables.scss';

.shared-session-icon {
  width: $dashboard-icon-width;
  height: $dashboard-icon-height;
  margin: 0 0.4rem;
}
</style>
